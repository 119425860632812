<template>
  <div class="relative w-full">
    <div v-if="label || required" class="flex items-center mb-4 w-full">
      <span v-if="label" class="input__label">
        {{ label }}
      </span>

      <span v-if="required" class="ml-auto text-sm text-signal-red"> Required </span>

      <span v-if="invalidText" class="ml-auto text-sm text-signal-red">
        {{ invalidText }}
      </span>
    </div>

    <input
      v-model="inputText"
      type="text"
      class="p-1 w-full placeholder-blue-default text-blue-default capitalize rounded input__field"
      autocomplete="wdadwa"
      @input="searchData"
    />

    <div v-if="showList && inputText.length > 0 && matchingData && matchingData.length > 0" class="list">
      <button
        v-for="(item, itemIndex) of matchingData"
        :key="itemIndex"
        class="list-item color--black-base"
        @click="selectItem(item)"
      >
        {{ item }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AutoComplete",

  props: {
    label: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      required: true,
    },
    invalidText: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      inputText: "",
      matchingData: [],
      showList: false,
    };
  },

  created() {
    if (this.value) {
      this.inputText = this.value;
    }
  },

  methods: {
    searchData() {
      this.showList = true;
      this.matchingData = this.data.filter((i) => i.toLowerCase().includes(this.inputText.toLowerCase()));
    },

    /**
     * @param {string|number} item
     */
    selectItem(item) {
      this.showList = false;

      this.inputText = item;

      this.$emit("selectedItem", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  border-radius: 3px;
  border: 1px solid #eee;
  position: absolute;
  z-index: 2000;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  height: auto;
  max-height: 20rem;
  margin-top: 0.125rem;
  background-color: #fff;

  .list-item {
    background-color: #fff;
    border: 1px solid #eee;
    text-align: left;
    padding: 0.5rem;
  }
}
</style>
